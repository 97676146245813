export default {
  "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2CB è un prodotto de The Nonsense Factory 2023 - "])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "warn_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENZIONE!"])},
  "warn_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il record verrà eliminato?"])},
  "warn_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "imageUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload dell'immagine eseguito"])},
  "imageUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l'upload dell'immagine"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registra"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
  "isInHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È in homepage"])},
  "isNotInHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è in homepage"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egeanet"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frazione Cervignasco 11 - 12037 Saluzzo (CN) Italy"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+", "39 0175 217323"])},
  "companyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "egeamusic.com"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright Egeamusic. All Rights Reserved"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indietro"])},
  "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovata"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore 404"])},
  "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla home"])},
  "apiErrors": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Trovato"])},
    "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore provando a contattare il server. Prova e rieseguire la login."])}
  },
  "importer": {
    "importExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa da Excel"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload del File Excel"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa"])},
    "downloadTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il template Excel"])}
  },
  "auth": {
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le credenziali non sono corrette."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non è corretta."])},
    "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troppi tentativi di login. Riprova tra :seconds secondi."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
  },
  "login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata?"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantieni l'accesso"])},
    "notUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei ancora un utente?"])},
    "signupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei un negozio e vuoi utilizzare Egeanet? Contattataci al numero  0175 217323 o via mail a"])},
    "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la password"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetta la tua password"])},
    "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia il link"])},
    "requestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inviata corretamente"])},
    "resetEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti abbiamo inviato una email con le istruzioni per resettare la tua password. Controlla anche nella cartella Spam."])},
    "passwordHasBeenReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password è stata resettata con successo!"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conferma"])},
    "backLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al login"])}
  },
  "filters": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca l'etichetta"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca la categoria"])},
    "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca la sottocategoria"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca l'autore"])},
    "showOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra prodotti esauriti"])},
    "resetFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella filtri"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo mese"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima settimana"])},
    "lastThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi tre mesi"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca l'area"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca il supporto"])},
    "clearTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella filtro date"])},
    "distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca il distributore"])}
  },
  "sortBy": {
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
    "ascRelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data uscita: ordine crescente"])},
    "descRelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data uscita: ordine decrescente"])},
    "azAuthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore: dalla A alla Z"])},
    "zaAuthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore: dalla Z alla A"])},
    "azTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo: dalla A alla Z"])},
    "zaTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo: dalla Z alla A"])},
    "azDistribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore: dalla A alla Z"])},
    "zaDistribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore: dalla Z alla A"])},
    "azSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto: dalla A alla Z"])},
    "zaSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto: dalla Z alla A"])},
    "ascOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinato: ordine crescente"])},
    "descOrdered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinato: ordine decrescente"])},
    "ascShipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaso: ordine crescente"])},
    "descShipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaso: ordine decrescente"])},
    "ascOnShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto a partire: ordine crescente"])},
    "descOnShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto a partire: ordine decrescente"])},
    "ascBackorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backorder: ordine crescente"])},
    "descBackorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backorder: ordine decrescente"])}
  },
  "backoffice": {
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotti"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchi"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "subcategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottocategorie"])},
      "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti"])},
      "customerGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi clienti"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini"])},
      "discountRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconti"])},
      "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne"])},
      "shippingMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodi di spedizione"])},
      "uoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
      "warehouses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzini"])},
      "warehouseTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni di magazzino"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generale"])}
    },
    "categories": {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Categorie"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Categoria"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Categoria"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza Categoria"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "inHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra in homepage"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Categorie da file Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Categorie sono stati importati"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])}
      }
    },
    "brands": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Brands"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Brand"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Brand"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza Brand"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "inHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra in homepage"])},
      "urlLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url del logo"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
      "noLogoExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è presente alcun logo"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Brands da file Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Brands sono stati importati"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])}
      },
      "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona i file"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica"])},
      "dragFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascina le immagini qui"])},
      "brandLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo del brand"])}
    },
    "uoms": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Unità di Misura"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Unità di Misura"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Unità di Misura"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "conversionRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto di conversione"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Unità di Misura da file Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Unità di Misura sono state importate"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])}
      }
    },
    "vats": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iva"])}
    },
    "attributeTags": {
      "attributeTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Attributi"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Tag Attributo"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Tag Attributo"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "alreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esiste già"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icona URL"])},
      "types": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testo"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])},
        "iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista"])},
        "fixedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista a scelta"])}
      },
      "newOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova opzione alla lista"])},
      "addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
      "fullIndexed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca Full Text"])}
    },
    "subCategories": {
      "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottocategoria"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Sottocategorie"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Sottocategoria"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Sottocategoria"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza Sottocategoria"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "inHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra in homepage"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Sottocategorie da file Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Sottocategorie sono stati importati"])},
      "referringCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria di riferimento"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])}
      },
      "attributeTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Attributi"])}
    },
    "products": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca Prodotto"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totali"])},
      "priceWithTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo Ivato"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Prodotti"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Prodotto"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Prodotto"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza Prodotto"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "supplierSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Fornitore"])},
      "erpProductCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice ERP"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchio"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottocategoria"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])},
      "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
      "distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore"])},
      "stockInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stock"])},
      "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "extendedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione estesa"])},
      "ean13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN13"])},
      "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
      "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
      "lowStockThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia Bassa Disponibilità"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
      "shippingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di spedizione"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profondità"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disp"])},
      "availableForOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinabile"])},
      "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non in magazzino"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile dal"])},
      "availableForPreorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile pre ordine"])},
      "availableOnRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile su richiesta"])},
      "hasVariants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varianti disponibili"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ord"])},
      "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/MM/yyyy"])},
      "import": {
        "imagesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa le Immagini Prodotto da Excel"])},
        "productTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Prodotti da file Excel"])},
        "attributesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa gli Attributi dei Prodotti da Excel"])},
        "discontinuedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna prodotti fuori catalogo"])},
        "availableFromTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna data uscita prodotti"])},
        "priceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna prezzi prodotti"])},
        "essentialsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna prodotti essentials"])},
        "spotlightTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna prodotti in the spotlight"])},
        "bigCarouselTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna prodotti nel carosello grande"])},
        "carouselTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna prodotti nel carosello"])},
        "imagesImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Immagini"])},
        "productImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Prodotti"])},
        "attributesImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Attributi"])},
        "discontinuedImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna fuori catalogo"])},
        "availableFromImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna data uscita"])},
        "priceImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna prezzi"])},
        "essentialsImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna essentials"])},
        "spotlightImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna spotlight"])},
        "bigCarouselImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna carosello grande"])},
        "carouselImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna carosello"])}
      },
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'import è stato inserito nella coda"])},
      "withTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con Tasse"])},
      "onRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su Richiesta"])},
      "discountedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo Scontato"])},
      "taxValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di cui IVA"])},
      "messages": {
        "GTZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il valore deve essere maggiore di 0"])},
        "isbnLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN deve essere 10 o 13 digits"])},
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])}
      },
      "addVariants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi varianti"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributi"])},
      "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti"])},
      "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])},
      "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino"])},
      "stocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stocks"])},
      "onHand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A magazzino"])},
      "onOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinato"])},
      "checkWarehouseMovements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza i movimenti di magazzino"])},
      "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine di copertina"])},
      "productImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini del prodotto"])},
      "availabilitySync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità aggiornata corretamente"])},
      "syncAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricalcola disponibilità"])}
    },
    "variants": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Varianti"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Variante"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Variante"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "supplierSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Fornitore"])},
      "erpProductCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice ERP"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchio"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variante"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottocategoria"])},
      "stockInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni Stock"])},
      "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "extendedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione estesa"])},
      "ean13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN13"])},
      "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
      "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
      "lowStockThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia Bassa Disponibilità"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larghezza"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profondità"])},
      "availableForOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinabile"])},
      "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non in magazzino"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile dal"])},
      "availableForPreorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile pre ordine"])},
      "availableOnRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile su richiesta"])},
      "hasVariants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varianti disponibili"])},
      "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/MM/yyyy"])},
      "messages": {
        "GTZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il valore deve essere maggiore di 0"])},
        "isbnLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN deve essere 10 o 13 digits"])},
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])}
      },
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinati"])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default"])},
      "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributi"])},
      "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisti"])},
      "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])}
    },
    "orders": {
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Ordini"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Ordine"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Ordine"])},
      "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero Ordine"])},
      "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla Ordine"])},
      "syncPanthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizza con Panthera"])},
      "closedOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine è stato annullato correttamente!"])},
      "cancelledOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine è stato annullato correttamente!"])},
      "cancelledRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La riga d'ordine ora risulta annullata"])},
      "shippedRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La riga d'ordine ora risulta spedita"])},
      "syncWithPanthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine re-inviato a Panthera correttamente"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
      "billingCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda di fatturazione"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo Totale"])},
      "currentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato ordine"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconto"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale Parziale"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Ordini da file Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli Ordini sono stati importati"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile da"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])}
      },
      "orderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["testata d'ordine"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])},
      "shippingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di spedizione"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato dell'ordine"])},
      "orderNumberERP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr ordine ERP"])},
      "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento:"])},
      "shippingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di spedizione"])},
      "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di spedizione"])},
      "orderRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Righe d'ordine"])},
      "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di fatturazione"])},
      "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun prodotto selezionato"])},
      "noCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun cliente corrisponde"])},
      "orderAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo prodotto è già nei tuoi ordini aperti. Ne hai ordinati:"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annulla"])},
      "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedito"])},
      "wantCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero annullare questo ordine?"])},
      "wantCancelRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero annullare questa riga?"])},
      "wantShippedRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi segnalare questa riga come spedita?"])},
      "importDeliveryNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa documenti di trasporto"])},
      "deliveryNotesQueued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti di trasporto: caricamento in corso"])},
      "importDigitalDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Documenti Digitali"])},
      "docDgtQueued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti Digitali: caricamento in corso"])},
      "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli ordine"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "orderStatus": {
        "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrato"])},
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa"])},
        "failed_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento fallito"])},
        "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermato"])},
        "in_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In lavorazione"])},
        "picking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In preparazione"])},
        "ready_to_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto per la spedizione"])},
        "on_ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In spedizione"])},
        "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedito"])},
        "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegnato"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullato"])},
        "partially_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente spedito"])},
        "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiuso"])}
      },
      "rowStatus": {
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellato"])},
        "not_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non spedito"])},
        "on_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In spedizione"])},
        "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedito"])}
      },
      "searchOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca l'ordine"])},
      "totalWithoutTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasse escluse"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinata"])},
      "shippedQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedita"])},
      "residualQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residua"])},
      "onShippingQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronta"])},
      "costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
      "customerOrderRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento ordine cliente"])},
      "customerOrderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ordine cliente"])}
    },
    "shippingMethods": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Metodi di Spedizione"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Metodo di Spedizione"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Metodo di Spedizione"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
      "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia Spedizione Gratuita"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Modalità di spedizione da file Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modalità di spedizione sono stati importati"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])}
      }
    },
    "customerGroups": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Gruppi Clienti"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Gruppo Clienti"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Gruppo Clienti"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "pantheraGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id Gruppo Panthera"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Gruppi Clienti da Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Gruppi Clienti sono stati importati"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])}
      }
    },
    "customers": {
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Clienti"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Cliente"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Cliente"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza Cliente"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "isERPConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connesso ad ERP?"])},
      "customerGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo Clienti"])},
      "ordersState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato ordini"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma Password"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privato"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "sdiCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice SDI"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "pec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pec"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile da"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoro"])},
      "vatId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id VAT"])},
      "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Fiscale"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconto"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
      "taxRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])},
      "preorderDaysAdvanceOnShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni pre-ordine"])},
      "taxDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA %"])},
      "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
      "newsletterOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzione Newsletter"])},
      "newsletterDateAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Attivazione Newsletter"])},
      "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Panthera"])},
      "erp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Erp"])},
      "getPantheraCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisisci Codice Panthera"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/MM/yyyy"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Clienti da Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Clienti sono stati importati"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la Nazione"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazione"])},
      "searchCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca Cliente per Nome"])},
      "customerDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconto Cliente"])},
      "extraCee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra CEE"])},
      "addresses": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
        "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presso"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia(sigla)"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazione"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci indirizzo"])},
        "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Indirizzo"])},
        "billing": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di Fatturazione"])}
        },
        "shipping": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi di spedizione"])}
        }
      },
      "panthera_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera customers"])},
      "get_panthera_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["get panthera code"])},
      "new_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo cliente Panthera"])},
      "select_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione cliente Panthera"])},
      "customer_not_in_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente che stai cercando non è nella lista?"])},
      "panthera_updatableCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi o crea un cliente Panthera"])},
      "customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente che stai cercando non ha trovato riscontro nei nostri registri"])},
      "add_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo cliente Panthera"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conferma"])},
      "toast_create_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente Panthera è stato aggiunto correttamente"])},
      "toast_panthera_customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente non trovato"])},
      "toast_customer_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente Panthera selezionato correttamente"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])},
        "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email non valida"])},
        "invalidVatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numer VAT sembra essere errato o il servizio controllo VAT non è attivo. Puoi verificare?"])},
        "invalidTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIl Codice Fiscale fornito non sembra essere corretto. Puoi verificarlo?"])},
        "validTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Codice Fiscale sembra essere corretto"])},
        "validVatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero VAT sembra essere corretto"])},
        "userCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User creato con successo"])},
        "passwordRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere di almeno 8 caratteri e contenere almeno un numero, un carattere maiuscolo ed un carattere speciale"])},
        "confirmPassworddDoesntMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non corrisponde."])}
      },
      "customerAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzi"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utente"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
      "deliveryNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti di trasporto"])},
      "DN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDT"])},
      "readyRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Righe pronte"])},
      "openRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Righe aperte"])}
    },
    "discountRules": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Regole Sconti"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggingi una Regola Sconto"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica una Regola Sconto"])},
      "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconto"])},
      "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorità"])},
      "blockOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocca Altri Sconti"])},
      "customerGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo Clienti"])},
      "productCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria Prodotto"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa le Regole di Sconto da Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Regole di sconto sono state importate"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])},
        "ruleWithBlockOthersAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste già una Regola di Sconto con il flag 'block others' impostato. Rimuovere la spunta dalla Regola di sconto che si sta inserendo"])},
        "sameRuleAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste già una Regola di sconto con la stessa priorità per questa coppia Gruppo Clienti/Categoria Prodotto. Modificare la opportunamente la Regola di Sconto che si sta inserendo."])}
      },
      "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite massimo raggiunto"])}
    },
    "campaigns": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Campagne"])},
      "newCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova campagna"])},
      "addProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovi prodotti alla campagna"])},
      "searchProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un prodotto"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titolo"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "inHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra in homepage"])},
      "productList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista prodotti"])},
      "describeCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornisci una breve descrizione della campagna"])},
      "campaignTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornisci un titolo per questa campagna"])},
      "campaignStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inizio campagna"])},
      "campaignEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data fine campagna"])},
      "noCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna campagna attiva per questa categoria"])},
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri i prodotti"])}
    },
    "warehouses": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Magazzini"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Magazzino"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Magazzino"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "managementType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità di gestione"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa magazzini da Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I magazzini sono stati importati correttamente"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])},
        "notspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Specificato"])}
      }
    },
    "transactions": {
      "documentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data documento"])},
      "documentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# documento"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione"])},
      "productVariantCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice variante"])},
      "previousStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock prima dell'operazione"])},
      "resultStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock dopo l'operazione"])},
      "productSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa transazioni da Excel"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni di magazzino"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice magazzino"])},
      "inventoryInitTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carcamento Inizalizzazione inventario"])},
      "inventoryBemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carico BEM"])},
      "inventoryAdjTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento Rettiche di Inventario"])},
      "inventoryTransferTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento Trasferimenti di Magazzino"])},
      "inventoryInitImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizalizzazione inventario Caricato"])},
      "inventoryBemImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem prodotto caricata"])},
      "inventoryAdjImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rettifiche di Inventario Caricate"])},
      "inventoryTransferImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti di Magazzino Caricati"])},
      "productTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimenti di Magazzino"])}
    },
    "attributes": {
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Attributo"])}
    },
    "cart": {
      "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrello"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il cliente"])}
    }
  },
  "frontoffice": {
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musica"])},
      "essential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indispensabili"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le novità"])},
      "comingUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossime uscite"])},
      "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promozioni"])},
      "inStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In stock"])},
      "homeVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home video"])},
      "subCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottocategorie"])},
      "hi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi-fi"])},
      "recordPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giradischi"])},
      "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessori Hi-fi"])},
      "merchandise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchandise"])},
      "clothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbigliamento"])},
      "gadget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gadget"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editoria"])},
      "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libri"])},
      "sheetMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spartiti"])},
      "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catalogo"])},
      "7inches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 pollici"])},
      "musicDvd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVD musicali"])},
      "musicBluRay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu-ray musicali"])},
      "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnalibri"])},
      "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne"])}
    },
    "cart": {
      "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrello"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale Parziale"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusa IVA"])},
      "novat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esclusa IVA"])},
      "vatAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ammontare IVA"])},
      "vatPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA %"])},
      "shippingCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di spedizione calcolati nella fase successiva"])},
      "shippingNational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per spedizione nazionale"])},
      "shippingInternational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per spedizione internazionale"])},
      "shippingVatAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ammontare IVA sul trasporto"])},
      "shippingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di spedizione"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo per il trasporto"])},
      "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di fatturazione"])},
      "shippingAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi di spedizione"])},
      "messages": {
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])},
        "productAddedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il prodotto è stato aggiunto al carrello"])}
      },
      "productsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi prodotti"])},
      "emptyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il carrello è vuoto"])},
      "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedi all'ordine"])},
      "defaultAddres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo predefinito"])}
    },
    "userData": {
      "myData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIEI DATA"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa il tuo profilo"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privato"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoro"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id IVA"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
      "changeLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia la lingua di default"])},
      "accessData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCESSO AI DATI"])},
      "mySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPOSTAZIONI"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
      "cannotChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non puoi cambiare il tipo di utenti perchè hai degli ordini in carico."])},
      "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un nuovo profilo di tipo diverso per poter inserire degli ordini"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
      "dateFormatPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/mm/yyyy"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/MM/yyyy"])}
    },
    "products": {
      "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine per"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli prodotto"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinati"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità"])},
      "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossime uscite"])},
      "moreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ dettagli"])},
      "searchProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca il prodotto"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novità"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchio"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottocategoria"])},
      "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinabile"])},
      "isDiscontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuori Catalogo"])},
      "noVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esclusa IVA"])},
      "shippingExcl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["escluse spese di spedizione"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])},
      "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
      "distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore"])}
    },
    "notifications": {
      "errorReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore. Si prega di ricaricare la pagina"])},
      "outOfStockFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il prodotto"])},
      "outOfStockSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stato rimosso dal carrello perché attualmente esaurito."])},
      "noMoreLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei più loggato!"])}
    },
    "checkout": {
      "orderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa l' Ordine"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione..."])},
      "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
      "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento:"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])},
      "workingOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendi la creazione dell'ordine ..."])},
      "addShippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo indirizzo di spedizione"])},
      "editShippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica l'indirizzo di spedizione"])},
      "messages": {
        "insertOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'inserimento dell'ordine. Riprova più tardi"])},
        "orderInserted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order inserito con successo."])}
      },
      "sameAsBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedisci all'indirizzo di fatturazione"])},
      "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di fatturazione"])},
      "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di spedizione"])},
      "shippingAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi di spedizione"])},
      "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C/O"])}
    },
    "coupons": {
      "hasACoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai un coupon?"])},
      "writeCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il codice del coupon:"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica"])}
    },
    "profile": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Personale"])},
      "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buongiorno"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio backorder"])},
      "orderRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Righe d'ordine aperte"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Miei Dati"])},
      "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Miei Indirizzi"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Miei Documenti"])},
      "coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
      "editAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica indirizzo di spedizione"])},
      "deleteAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina indirizzo di spedizione"])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questo indirizzo?"])},
      "order": {
        "productSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice articolo"])},
        "productDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione prodotto"])},
        "totQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot qtà ordinata"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
        "order_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ordine"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli prodotto"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale ordinato"])},
        "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinato"])},
        "allocated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto a partire"])},
        "backorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backorder"])},
        "searchProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca il prodotto"])},
        "onlyReadyShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza solo Pronto a partire"])},
        "downloadExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica file Excel"])},
        "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaso"])}
      },
      "readyShipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto per la spedizione"])},
      "shippingDisclaimerToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le richieste di spedizione ricevute entro le ore 14:00 saranno evase in giornata."])},
      "shippingDisclaimerTomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le richieste ricevute oltre tale orario saranno evase il giorno successivo."])},
      "shippingTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale da spedire"])},
      "requestShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi spedizione"])},
      "shippingReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qtà pronta"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo tot"])},
      "shippingRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronto per la spedizione"])},
      "deliveryNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti di trasporto"])},
      "requestSuccessfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La richiesta è stata inoltrata correttamente"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
      "deliveryNoteNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero documento di trasporto"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copertina"])},
      "deliveryNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento di trasporto:"])},
      "digitalDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti digitali"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatture"])},
      "ddtDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica DDT"])},
      "invoicesDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica fatture"])},
      "invoiceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero fattura"])},
      "noInvoiceAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono fatture al momento disponibili"])},
      "noTdAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono DDT al momento disponibili"])},
      "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pezzi"])}
    },
    "documents": {
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatture"])},
      "credit-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note di Credito"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data importo"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo Ordine"])}
    },
    "orders": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero d'ordine"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data ordine"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importo ordine"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato dell'ordine"])},
      "status-in_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Lavorazione"])},
      "status-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedito"])},
      "status-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "status-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrato"])},
      "status-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Attesa"])},
      "status-failed_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento fallito"])},
      "status-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullato"])},
      "status-picking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In preparazione"])}
    },
    "order": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dettagli ordine"])},
      "invoice-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra Fattura"])},
      "status-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Processato"])},
      "status-transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. In Transito"])},
      "status-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Spedito"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero d'Ordine"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Ordine"])},
      "billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo fatturazione"])},
      "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di spedizione"])},
      "shipping-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di spedizione"])},
      "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
      "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale parziale"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedizione"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
      "inc-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incl. IVA"])},
      "tracking-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di spedizione"])},
      "tracking-sentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica lo stato della spedizione "])},
      "tracking-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine in attesa di pagamento."])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il pagamento non verrà effettuato in una settimana l'ordine verrà cancellato."])},
      "noVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. IVA"])},
      "waitingConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di conferma di pagamento"])},
      "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedizione gratuita"])}
    },
    "addressForm": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C/O"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via/Piazza"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Postale"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia(sigla)"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazione"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Fiscale"])},
      "taxNumberBracket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(obbligatorio solo per i cittadini italiani)"])},
      "taxNumberMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice fiscale non corrisponde a quello di una persona fisica. Se sei un'azienda per favore contatta il supporto tramite email."])}
    },
    "payments": {
      "stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta di credito"])},
      "paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal"])},
      "paypal-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrai ridirezionato sul sito PayPal"])},
      "stripe-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sto processando il pagamento..."])},
      "sepa-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sto processando il pagamento..."])},
      "sofort-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrai ridirezionato sul sito Sofort"])},
      "sepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addebito Diretto Sepa"])},
      "sofort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banca Sofort"])},
      "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento alla consegna"])},
      "erp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come da accordi"])}
    },
    "password": {
      "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio password"])},
      "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
      "newConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la nuova password"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva la nuova password"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è stata aggiornata"])},
      "updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'aggiornamento della password. Per favore ritente più tardi."])},
      "messages": {
        "passwordRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere di almeno 8 caratteri e contenere almeno un numero, un carattere maiuscolo ed un carattere speciale"])},
        "confirmPassworddDoesntMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non corrisponde."])}
      }
    },
    "settings": {
      "showDiscontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra i fuori catalogo"])},
      "saveData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva le impostazioni"])},
      "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni Salvate"])},
      "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel salvataggio delle impostazioni"])}
    },
    "searchBox": {
      "HOME_VIDEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Video"])},
      "MUSICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musica"])},
      "ABBIGLIAMENTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbigliamento"])},
      "ACCESSORI_HIFI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessori Hi-Fi"])},
      "EDITORIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editoria"])},
      "GADGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gadget"])},
      "GIRADISCHI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giradischi"])},
      "LIBRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libri"])},
      "MERCHANDISE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchandise"])},
      "MOBILETTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiletto"])},
      "SPARTITI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spartiti"])},
      "A.P.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.P."])},
      "BLURAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu-ray"])},
      "BLURAY_HOME_VIDEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu-ray Home Video"])},
      "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CD"])},
      "CD-S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CD-S"])},
      "DUALDISC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DualDisc"])},
      "DVD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVD"])},
      "DVD_HOME_VIDEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVD Home Video"])},
      "EP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EP"])},
      "LIBRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro"])},
      "LP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP"])},
      "LP-Mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP-Mix"])},
      "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MC"])},
      "SACD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SACD"])},
      "SPAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPAR"])},
      "T-SHIRT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T-SHIRT"])},
      "VHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VHS"])}
    },
    "customers": {
      "searchCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca Cliente per Nome"])}
    }
  },
  "homepage": {
    "exclusiveDistribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuzioni esclusive"])}
  }
}